import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAttendenceMonth = createAsyncThunk(
  "/attendence/get",
  async (
    { acadyear, month },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const formData = new FormData();
      const { year, branch, reg_no } = getState().auth.user;
      formData.append("year", year);
      formData.append("branch", branch.toLowerCase());
      formData.append("acadyear", acadyear);
      formData.append("req", month);
      formData.append("regno", reg_no);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "attendence.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: error.response.data?.msg });
    }
  }
);

export const getAttendenceDay = createAsyncThunk(
  "/attendence/get/day",
  async (
    { acadyear, date },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const formData = new FormData();
      const { year, branch, reg_no } = getState().auth.user;
      formData.append("year", year);
      formData.append("branch", branch.toLowerCase());
      formData.append("acadyear", acadyear);
      formData.append("day", date);
      formData.append("regno", reg_no);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "dayattendence.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: error.response.data?.msg });
    }
  }
);

export const AttancdenceSlice = createSlice({
  name: "attendence",
  reducers: {},
  initialState: {
    data: [],
    dataDay: [],
    pending: false,
    error: null,
    filtered: [],
  },
  extraReducers: {
    [getAttendenceMonth.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.pending = false;
      state.dataDay = [];
      state.error = null;
    },
    [getAttendenceMonth.pending]: (state) => {
      state.pending = true;
    },
    [getAttendenceMonth.rejected]: (state, action) => {
      state.error = action.payload;
      state.data = [];
    },
    [getAttendenceDay.fulfilled]: (state, action) => {
      state.dataDay = action.payload;
      state.pending = false;
      state.error = null;

      state.data = [];
    },
    [getAttendenceDay.pending]: (state) => {
      state.pending = true;
    },
    [getAttendenceDay.rejected]: (state, action) => {
      state.error = action.payload;
      state.dataDay = [];
    },
  },
});
