import React from "react";
import { NavLink as Link } from "react-router-dom";

function NavLink({ title, NavIcon, path, onClick }) {
  const activeclass = "primaryBgLinear text-white font-medium";
  const inactiveclass = "text-textSecondary md:hover:text-primary";

  return (
    <Link
      onClick={onClick}
      to={title === "Circulars" ? "/circular" : path}
      end={title === "Circulars" ? false : true}
      className={({ isActive }) =>
        "group flex items-center mt-[12px] md:mt-[20px] w-[190px] px-[26px] py-[11px] rounded-md cursor-pointer " +
        (isActive ? activeclass : inactiveclass)
      }
    >
      <NavIcon className={"text-[24px]"} />
      <span
        className={
          "ml-[20px] text-[15px] " + (title === "Logout" && "font-bold")
        }
      >
        {title}
      </span>
    </Link>
  );
}

export default NavLink;
