import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRemarks } from "../../store/remarks.slice";
import RemTable from "./RemTable";

function Remarks() {
  const dispatch = useDispatch();
  const remarks = useSelector((state) => state.remark.data);

  useEffect(() => {
    dispatch(getRemarks({ acadyear: "2021" }));
  }, []);

  if (remarks.length == 0)
    return (
      <div className="px-primaryMob py-primaryMob flex justify-center text-xl">
        <h1>No Remarks Yet {getRemarks} !</h1>
      </div>
    );
  return (
    <div>
      <RemTable />
    </div>
  );
}

export default Remarks;
