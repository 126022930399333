import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getSchedule = createAsyncThunk(
  "/schedule/get",
  async (
    {test },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const formData = new FormData();
      const { branch,year } = getState().auth.user;
      formData.append("year", year);
      formData.append("branch", branch);
      formData.append("test_type", test);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "scheduleretrieve.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: error.response.data?.msg });
    }
  }
);

export const ScheduleSlice = createSlice({
  name: "schedule",
  reducers: {},
  initialState: {
    data: [],
    pending: false,
    error: null,
  },
  extraReducers: {
    [getSchedule.fulfilled]: (state, action) => {
      state.pending = false;
      state.error = null;
      state.data = action.payload?.map(
        ({ subname, subcode, date, start, end }) => {
          return {
            "Subject Code": subcode,
            "Subject Name": subname,
            "Exam Date": date,
            "Start Time": start,
            "End Time": end,
          };
        }
      );
    },
    [getSchedule.pending]: (state) => {
      state.pending = true;
    },
    [getSchedule.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});
