import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/auth.slice";
import Selector from "../utils/JBPSelect";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Login = () => {
  const [text, settext] = useState("");
  const [year, setYear] = useState("1");
  const [phone, setPhone] = useState("");
  const [branch, setBranch] = useState("PCMB");
  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.auth.pending);

  function toUpper(e) {
    let reg = e.target.value;
    settext(reg.toUpperCase());
  }

  async function onLogin() {
    await dispatch(
      login({
        year: year,
        regno: text,
        branch: branch,
        acadyear: "2023",
        phone: phone,
      })
    );
  }

  return (
    <div className="pb-24 w-[100vw] h-[100vh] flex flex-col justify-center items-center bg-bg">
      <div className="bg-bgPrimary w-[85%] md:px-20 h-fit m-primaryMob md:w-1/2 lg:w-1/3 md:h-[70%] p-primaryMob shadow-xl rounded-lg border border-primary flex flex-col items-center justify-around">
        <div className="text-primary font-bold font-sans text-2xl">
          <span>Nexuss</span>
        </div>
        <span
          className=" text-textSecondary mt-1
         text-xl"
        >
          Student Login
        </span>
        <span className="flex items-center gap-3 justify-between w-full mt-8">
          <Selector
            title={"Select Year"}
            onChange={(e) => setYear(e.target.value)}
            options={["1", "2"]}
            value={year}
          />
          <Selector
            title={"Select Branch"}
            onChange={(e) => setBranch(e.target.value)}
            options={[
              "PCMB",
              "EBAC",
              "PCMC",
              "SECTION A",
              "SECTION B",
              "SECTION C",
              "SECTION D",
              "SECTION F",
              "SECTION G",
              "LANGUAGE",
            ]}
            values={[
              "PCMB",
              "EBAC",
              "PCMC",
              "SECA",
              "SECB",
              "SECC",
              "SECD",
              "SECF",
              "SECG",
              "LANG",
            ]}
            value={branch}
          />
        </span>
        <input
          onChange={(e) => {
            toUpper(e);
          }}
          type="text"
          className="border-b-2 bg-transparent outline-none placeholder-slate-400 focus:border-blue-400 w-full mt-2 py-2"
          placeholder="Enter Reg No"
          value={text}
        />

        <input
          onChange={(e) => {
            //setphone
            setPhone(e.target.value);
          }}
          type="text"
          className="border-b-2 bg-transparent outline-none placeholder-slate-400 focus:border-blue-400 w-full mt-2 py-2"
          placeholder="Enter Phone No"
          value={phone}
        />

        <input
          type="button"
          className="border bg-transparent disabled:border-yellow-400 disabled:text-yellow-400 disabled:bg-yellow-500 font-bold border-green-600 mt-8 w-[50%] px-primaryMob py-2 rounded-full text-green-600  hover:bg-green-500 cursor-pointer hover:text-white"
          value="Login"
          // disabled={isPending}
          onClick={onLogin}
        />
        <a
          href="https://pu.jbportals.com/"
          className="hover:underline font-semibold flex items-center gap-2 text-primary mt-5 text-sm"
        >
          <AiOutlineArrowLeft /> Back To JB Portals.com
        </a>
      </div>
    </div>
  );
};

export default Login;
