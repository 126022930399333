import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getTimetable = createAsyncThunk(
  "/timetable/get",
  async (_payload, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const formData = new FormData();
      const { year, branch } = getState().auth.user;
      formData.append("year", year);
      formData.append("branch", branch);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "timetableretrieve.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: error.response.data?.msg });
    }
  }
);

export const TimeTableSlice = createSlice({
  name: "timetable",
  reducers: {},
  initialState: {
    data: [],
    pending: false,
    error: null,
    filtered: [],
  },
  extraReducers: {
    [getTimetable.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.filtered = action.payload.filter((tdata) => tdata.day == "MONDAY");
      state.pending = false;
    },
    [getTimetable.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [getTimetable.rejected]: (state, action) => {
      console.log(action);
      state.error = action.payload?.msg;
      state.pending = false;
    },
  },
});
