import React, { useState } from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineClockCircle,
  AiOutlineDownload,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { motion } from "framer-motion";
import Supabase from "../utils/Supabase";
import {
  Badge,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagRightIcon,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAssignments } from "../../store/assignments.slice";

function Card({ assignment }) {
  const [file, setFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useDispatch();

  async function uploadAnswerScript() {
    setIsUploading(true);
    try {
      const response = await Supabase()
        .storage.from("media")
        .upload(
          `assignscripts/unittest-${assignment.unitno}-${user.reg_no}`,
          file,
          {
            upsert: true,
            cacheControl: "no-cache",
          }
        );
      const formData = new FormData();
      formData.append("name", user.name);
      formData.append("branch", user.branch);
      formData.append("reg_no", user.reg_no);
      formData.append("year", user.year);
      formData.append("subcode", assignment.subcode);
      formData.append("unitno", assignment.unitno);
      formData.append("assignno", assignment.assignno);
      formData.append("url", response.data.path);
      await axios(process.env.REACT_APP_API_URL + "assignmentupload.php", {
        method: "POST",
        data: formData,
      });
      toast.success("Asssignment script submitted successfully");
      await dispatch(getAssignments());
      setFile(null);
      onClose();
    } catch (e) {
      console.log(e)
      toast.error("something went wrong! try again later");
    }
    setIsUploading(false);
  }

  async function onReuploadScript() {
    setIsUploading(true);
    try {
      const response = await Supabase()
        .storage.from("media")
        .upload(
          `assignscripts/unittest-${assignment.unitno}-${user.reg_no}`,
          file,
          {
            upsert: true,
            cacheControl: "no-cache",
          }
        );
      const formData = new FormData();
      formData.append("id", assignment.aid);
      formData.append("status", "PENDING");
      await axios(
        process.env.REACT_APP_API_URL + "assignmentupdatestatus.php",
        {
          method: "POST",
          data: formData,
        }
      );
      toast.success("Asssignment script submitted successfully");
      dispatch(getAssignments());
      setFile(null);
      onClose();
    } catch (e) {
      toast.error("something went wrong! try again later");
    }
    setIsUploading(false);
  }

  return (
    <motion.div
      layout
      animate={{ opacity: 1, y: 0, scale: 1 }}
      initial={{ opacity: 0, y: 20, scale: 0.7 }}
      transition={{ duration: 0.4, type: "spring" }}
      className="bg-white w-full h-full mt-4 pt-3 px-4 border rounded-md md:mt-0 text-sm shadow-lg border-primary"
    >
      <Modal
        size={"md"}
        isCentered
        isOpen={isOpen}
        onClose={!isUploading && onClose}
      >
        <ModalOverlay className="backdrop-blur-sm" />
        <ModalContent>
          <ModalHeader>
            <b>
              {assignment.subcode} - Unit No. {assignment.unitno} - Assign No.{" "}
              {assignment.assignno}
            </b>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody h={"90vh"}>
            <VStack h={"full"} w={"full"}>
              <FormControl>
                <FormLabel>Select Assignment Script (.pdf) file</FormLabel>
                <Input
                  isDisabled={isUploading}
                  accept="application/pdf"
                  onChange={(e) => setFile(e.target.files[0])}
                  variant={"filled"}
                  colorScheme="blue"
                  alignItems={"center"}
                  type="file"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                isDisabled={isUploading}
                onClick={onClose}
                colorScheme="blue"
                variant={"outline"}
              >
                Cancel
              </Button>
              <Button
                isLoading={isUploading}
                onClick={
                  assignment.status == "NOT SUBMITTED"
                    ? uploadAnswerScript
                    : assignment.status == "REUPLOAD"
                    ? onReuploadScript
                    : () => {}
                }
                colorScheme="blue"
                isDisabled={!file}
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className="flex justify-between relative">
        <b className="flex-1 whitespace-nowrap">Subject Code</b>{" "}
        <span className="text-left flex-1">{assignment.subcode}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Unit No.</b>{" "}
        <span className="text-left flex-1">{assignment.unitno}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Assignment No.</b>{" "}
        <span className="text-left flex-1">{assignment.assignno}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Submission Date</b>{" "}
        <span className="text-left flex-1">{assignment.submission_date}</span>
      </div>
      {
        assignment.status=="ACCEPTED"&&(
          <div className="flex justify-between my-3">
        <b className="flex-1">Submitted Date</b>{" "}
        <span className="text-left flex-1">{assignment.submitted_date}</span>
      </div>
        )
      }
      <hr className="" />
      <div className="py-3 flex flex-col justify-between px-2 items-center">
        <HStack w={"full"} justifyContent={"space-between"}>
          <span className="text-inherit text-sm">{assignment.date}</span>
          <Button
            variant={"unstyled"}
            onClick={async () => {
              const file = await Supabase()
                .storage.from("media")
                .download(assignment.file_url);
              const path = window.URL.createObjectURL(file.data);
              const link = document.createElement("a");
              link.href = path;
              link.setAttribute(
                "download",
                `Assignment-${assignment.subcode}-Unit ${assignment.unitno}-Assignment ${assignment.assignno}.pdf`
              );
              document.body.appendChild(link);

              // Start download
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);
            }}
          >
            <AiOutlineDownload className="text-[24px] text-inherit hover:scale-110 active:scale-95 cursor-pointer" />
          </Button>
        </HStack>
        <HStack w={"full"}>
          {assignment.status == "ACCEPTED" ? (
            <Tag
              colorScheme="whatsapp"
              justifyContent={"center"}
              size={"lg"}
              variant={"solid"}
              py={"3"}
              w={"full"}
            >
              Submitted
              <TagRightIcon>
                <AiOutlineCheckCircle className="text-2xl" />
              </TagRightIcon>
            </Tag>
          ) : assignment.status == "PENDING" ? (
            <Tag
              colorScheme="orange"
              justifyContent={"center"}
              size={"lg"}
              variant={"solid"}
              py={"3"}
              w={"full"}
            >
              Review Pending
              <TagRightIcon>
                <AiOutlineClockCircle className="text-2xl" />
              </TagRightIcon>
            </Tag>
          ) : assignment.status == "NOT SUBMITTED" ? (
            <Button
              w={"full"}
              onClick={onOpen}
              colorScheme="blue"
              leftIcon={<AiOutlineFilePdf className="text-xl" />}
            >
              Submit Assignment Script
            </Button>
          ) : assignment.status == "REUPLOAD" ? (
            <Button
              w={"full"}
              onClick={onOpen}
              colorScheme="purple"
              leftIcon={<AiOutlineFilePdf className="text-xl" />}
            >
              Submit Again
            </Button>
          ) : null}
        </HStack>
      </div>
    </motion.div>
  );
}
export default Card;
