import React from "react";

const Attend = (props) => {
  const totalClasses = 18;
  const attended = 8;
  const percentage = ((attended / totalClasses) * 100).toFixed(0);

  return (
    <div className="p-primaryMob">
      <div className="flex w-fit gap-2">
        <div
          className="p-primaryMob border border-[#EA4D90] md:p-4 shadow-[0_0_8px_1px] shadow-[#EA4D90]
       md:text-[#EA4D90] md:bg-white
        md:hover:text-white md:hover:bg-[#EA4D90]
        text-white bg-[#EA4D90] 
        drop rounded-sm flex flex-col items-center
        "
        >
          <span className="">Total Absent</span>
          <span className="text-8xl">{props.attendence.abs}</span>
        </div>
        <div
          className=" p-primaryMob border border-[#21D2FF] md:p-4 shadow-[0_0_8px_1px] shadow-[#21D2FF]
       md:text-[#21D2FF] md:bg-white
       md:hover:text-white md:hover:bg-[#21D2FF]
       text-white bg-[#21D2FF] 
        drop rounded-sm flex flex-col items-center
        "
        >
          <span className="">Total Present</span>
          <span className="text-8xl">{props.attendence.abs}</span>
        </div>
      </div>
      <div className="py-2">
        <span className="text-lg font-medium">Percentage</span>
        <div className="w-[full] h-[10px] bg-blue-100 rounded-full mt-4 overflow-hidden">
          <div
            className={`h-full bg-primary rounded-r-full`}
            style={{ width: percentage + "%" }}
          ></div>
        </div>
        <div className="w-full flex justify-end py-2">
          <span className="text-lg font-bold text-primary">{percentage}%</span>
        </div>
      </div>
    </div>
  );
};

Attend.defaultProps = {
  attendence: {
    abs: 5,
    prs: 15,
  },
};

export default Attend;
