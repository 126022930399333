import React, { useEffect } from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPendingAssignments } from "../../store/assignments.slice";

function Assignments() {
  const assignmentCount = useSelector((state) => state.assignments.count);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPendingAssignments({ acadyear: "2021" }));
  }, []);
  return (
    <div className="px-primaryMob pb-primaryMob md:px-5">
      <Link to={"/assignments"}>
        <div className="hover:cursor-pointer md:hover:scale-105 ease-in-out duration-300 overflow-hidden relative h-[170px] w-full bg-gradient-to-r rounded-[10px] from-[rgba(240,223,65,0.58)] to-[rgba(255,230,0,0.6)] shadow-lg border-2 border-white">
          <div className="px-[35px] py-[25px] flex flex-col justify-between w-full absolute h-full bg-[rgba(0,0,0,0.03)] backdrop-blur-lg">
            <div className="flex-1 flex justify-between">
              <span className="text-xl font-bold">Assignments</span>
              <AiOutlineFileText className="text-[42px]" />
            </div>
            <div className="flex-1  justify-end">
              <h1 className="font-bold text-5xl">{assignmentCount}</h1>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Assignments;
