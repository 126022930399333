import React from "react";
import { useSelector } from "react-redux";

const RemTable = () => {
  const remarks = useSelector((state) => state.remark.data);

  return (
    <div className="px-primaryMob">
      <div className="md:flex mt-10 w-full h-fit overflow-auto customScrollLight ">
        <table className={`w-full px-3`}>
          <thead>
            <tr className="px-3 text-sm border-y-[2px] dark:bg-dark dark:text-white bg-gray-200  dark:border-white font-semibold ">
              <td className="text-start py-4 px-4 whitespace-nowrap">Sl no.</td>
              <td className="text-start py-4 px-4 whitespace-nowrap">
                Categoery
              </td>
              <td className="text-start py-4 px-4 whitespace-nowrap">Date</td>
              <td className="text-start py-4 px-4 whitespace-nowrap">
                Remarks
              </td>
            </tr>
          </thead>
          <tbody className="px-3">
            {remarks.map((remark, index) => {
              return (
                <tr
                  key={index}
                  className="text-sm dark:bg-lightDark dark:text-white bg-gray-100 border-t-[4px] dark:border-white border-gray-200"
                >
                  <td className="px-5 py-6 font-semibold whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap">
                    {remark?.category}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap">
                    {remark?.date}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap h-[60px] w-fit lg:w-full overflow-auto dark:customScroll customScrollLight">
                    {remark?.remark}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RemTable;
