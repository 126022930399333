import React from "react";

function TTCard({ period }) {
  return (
    <div className="w-full grid grid-cols-3 mt-4">
      <div className="flex items-start pr-5 justify-start border-r-4 border-primary">
        <div className="flex flex-col items-center">
          <span className="text-xs">{period.from}</span>
          <div className="w-[1px] h-[10px] bg-black"></div>
          <span className="text-xs">{period.to}</span>
        </div>
      </div>
      <div className="flex justify-start col-span-2 pl-2 items-center">
        <h1 className="text-sm">{period.name}</h1>
      </div>
    </div>
  );
}

export default TTCard;
