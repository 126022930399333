import React from "react";

function Attendence() {
  const totalClasses = 18;
  const attended = 8;
  const percentage = ((attended / totalClasses) * 100).toFixed(0);
  return (
    <div className="px-primaryMob md:px-5">
      <div className="bg-white px-[35px] pt-primaryMob rounded-[10px] shadow-xl border">
        <div className="flex w-full justify-between">
          <div className="flex flex-col justify-between">
            <div>
              <h2 className="font-bold text-xl">Total Classes</h2>
              <h3>(this week)</h3>
            </div>
            <div className="mt-2 border flex justify-center items-center px-[20px] py-2 rounded-md">
              <h1 className="text-2xl font-bold">{totalClasses}</h1>
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <h2 className="font-bold text-xl">Attended</h2>
            <div className="border flex justify-center items-center px-[20px] py-2 rounded-md">
              <h1 className="text-2xl font-bold">{attended}</h1>
            </div>
          </div>
        </div>
        <div className="py-2">
          <span className="text-lg font-medium">Percentage</span>
          <div className="w-full h-[10px] bg-blue-100 rounded-full mt-4 overflow-hidden">
            <div
              className={`h-full bg-primary rounded-r-full`}
              style={{ width: percentage + "%" }}
            ></div>
          </div>
          <div className="w-full flex justify-end py-2">
            <span className="text-lg font-bold text-primary">
              {percentage}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendence;
