import React from "react";

function JBPSelect({
  title,
  placeholder,
  options,
  onChange,
  value,
  className,
  values,
}) {
  return (
    <div
      className={"flex flex-col lg:ml-5  lg:first:ml-0 md:ml-0 " + className}
    >
      <label className="flex flex-col  text-md py-2 font-bold">{title}</label>
      <select
        {...{ onChange }}
        {...{ value }}
        className="w-fit bg-bg shadow-md active:shadow-none active:scale-95 text-md py-2 px-2 rounded-[8px] text-textSecondary outline-primary border border-textSecondary"
      >
        {placeholder && (
          <option value={placeholder} disabled selected className="px-2">
            {placeholder}
          </option>
        )}
        {options.map((optionName, index) => {
          return (
            <option
              value={values?.length ? values[index] : optionName}
              key={index}
            >
              {optionName}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default JBPSelect;
