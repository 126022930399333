import React, { useEffect } from "react";
import TTCard from "./TTCard";
import { useDispatch } from "react-redux";
import { getTimetable } from "../../store/timetable.slice";

function TimeTable() {
  const periodsData = [
    {
      from: "9:00 AM",
      to: "10:00 AM",
      name: "Engg. Maths-1 (Test)",
    },
    {
      from: "10:00 AM",
      to: "11:00 AM",
      name: "Engg. Maths-1 (Test)",
    },
    {
      from: "11:00 AM",
      to: "12:00 PM",
      name: "Engg. Maths-1 (Test)",
    },
    {
      from: "1:00 PM",
      to: "1:30 PM",
      name: "Lunch Break",
      break: true,
    },
    {
      from: "1:30 PM",
      to: "2:30 PM",
      name: "CS Lab",
      break: true,
    },
    {
      from: "2:30 PM",
      to: "3:30 PM",
      name: "CS Lab",
      break: true,
    },
    {
      from: "3:30 PM",
      to: "4:30 PM",
      name: "CS Lab",
      break: true,
    },
  ];

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getTimetable())
  },[dispatch])

  return (
    <div className="px-primaryMob py-primaryMob md:px-2 xl:px-0 lg:py-0">
      <div className="w-full h-fit bg-white shadow-xl rounded-[10px] py-[25px] px-[35px] border">
        <header>
          <h1 className="font-bold text-xl">Time Table</h1>
        </header>
        <div className="py-5">
          <select className="px-3 border py-1 rounded-md outline-none font-medium bg-white">
            <option className="px-3 py-2 text-lg">Monday</option>
            <option>Tuesday</option>
            <option>Wednesday</option>
            <option>Thursday</option>
            <option>Friday</option>
            <option>Saturday</option>
          </select>
        </div>
        <div>
          {periodsData.map((period, index) => {
            return <TTCard {...{ period }} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default TimeTable;
