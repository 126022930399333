import React from "react";

function JBButton({ children, disabled, onClick, full, status, className }) {
  return (
    <button
      disabled={disabled || status === "PENDING" || status === "APPROVED"}
      {...{ onClick }}
      className={
        "h-fit bg-primary py-2 px-3 rounded-md text-white font-medium group active:scale-95 disabled:active:scale-100 " +
        (disabled && "bg-gray-400 text-black") +
        " " +
        (full ? "w-full" : "w-fit") +
        " " +
        (status === "APPROVED" && " bg-green-600 text-white") +
        " " +
        (status === "DENIED" && " bg-red-500 text-white") +
        " " +
        (status === "PENDING" ? " bg-gray-400 text-black" : "")
      }
    >
      {children}
    </button>
  );
}

export default JBButton;
