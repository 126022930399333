import React from "react";
import {
  AiOutlineDownload,
} from "react-icons/ai";
import { motion } from "framer-motion";
import Supabase from "../utils/Supabase";
import {
  Button,
  HStack,
} from "@chakra-ui/react";

function Card({ note }) {

  return (
    <motion.div
      layout
      animate={{ opacity: 1, y: 0, scale: 1 }}
      initial={{ opacity: 0, y: 20, scale: 0.7 }}
      transition={{ duration: 0.4, type: "spring" }}
      className="bg-white w-full h-full mt-4 pt-3 px-4 border rounded-md md:mt-0 text-sm shadow-lg border-primary"
    >
      <div className="flex justify-between relative">
        <b className="flex-1 whitespace-nowrap">Subject Code</b>{" "}
        <span className="text-left flex-1">{note.subcode}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Unit No.</b>{" "}
        <span className="text-left flex-1">{note.unitno}</span>
      </div>
      <hr className="" />
      <div className="py-3 flex flex-col justify-between px-2 items-center">
        <HStack w={"full"} justifyContent={"space-between"}>
          <span className="text-inherit text-sm">{note.uploaded_date}</span>
          <Button
            variant={"unstyled"}
            onClick={async () => {
              const file = await Supabase()
                .storage.from("media")
                .download(note.filename);
              const path = window.URL.createObjectURL(file.data);
              const link = document.createElement("a");
              link.href = path;
              link.setAttribute(
                "download",
                `Notes-${note.subcode}-Unit-${note.unitno}.pdf`
              );
              document.body.appendChild(link);

              // Start download
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);
            }}
          >
            <AiOutlineDownload className="text-[24px] text-inherit hover:scale-110 active:scale-95 cursor-pointer" />
          </Button>
        </HStack>
      </div>
    </motion.div>
  );
}
export default Card;
