import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getTeachers = createAsyncThunk(
  "/teachers/get",
  async (_payload, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const formData = new FormData();
      const { branch } = getState().auth.user;
      formData.append("branch", branch);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "teachers.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: "something went wrong !" });
    }
  }
);

export const TeachersSlice = createSlice({
  name: "teachers",
  reducers: {},
  initialState: {
    data: [],
    pending: false,
    error: null,
  },
  extraReducers: {
    [getTeachers.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.pending = false;
    },
    [getTeachers.pending]: (state) => {
      state.pending = true;
    },
    [getTeachers.rejected]: (state, action) => {
      state.error = action.payload;
      state.data = [];
    },
  },
});
