import React, { useState } from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineDownload,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { motion } from "framer-motion";
import Supabase from "./Supabase";
import {
  Badge,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagRightIcon,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getUT } from "../../store/ut.slice";

function JBPCard({ note }) {
  const [file, setFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useDispatch();

  async function uploadAnswerScript() {
    setIsUploading(true);
    try {
      const response = await Supabase()
        .storage.from("media")
        .upload(`answers/unittest-${note.unitno}-${user.reg_no}`, file, {
          upsert: true,
          cacheControl: "no-cache",
        });
      const formData = new FormData();
      formData.append("name", user.name);
      formData.append("branch", user.branch);
      formData.append("reg_no", user.reg_no);
      formData.append("year", user.year);
      formData.append("subcode", note.subcode);
      formData.append("unitno", note.unitno);
      formData.append("utno", note.testno);
      formData.append("uturl", response.data.path);
      await axios(process.env.REACT_APP_API_URL + "utanswersupload.php", {
        method: "POST",
        data: formData,
      });
      toast.success("Answer script submitted successfully");
      await dispatch(getUT());
      setFile(null);
      onClose();
    } catch (e) {
      toast.error("something went wrong! try again later");
    }
    setIsUploading(false);
  }

  return (
    <motion.div
      layout
      animate={{ opacity: 1, y: 0, scale: 1 }}
      initial={{ opacity: 0, y: 20, scale: 0.7 }}
      transition={{ duration: 0.4, type: "spring" }}
      className="bg-white w-full h-full mt-4 pt-3 px-4 border rounded-md md:mt-0 text-sm shadow-lg border-primary"
    >
      <Modal
        size={"md"}
        isCentered
        isOpen={isOpen}
        onClose={!isUploading && onClose}
      >
        <ModalOverlay className="backdrop-blur-sm" />
        <ModalContent>
          <ModalHeader>
            <b>
              {note.subcode} - Unit No. {note.unitno}
            </b>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody h={"90vh"}>
            <VStack h={"full"} w={"full"}>
              <FormControl>
                <FormLabel>Select Answer Script (.pdf) file</FormLabel>
                <Input
                  isDisabled={isUploading}
                  accept="application/pdf"
                  onChange={(e) => setFile(e.target.files[0])}
                  variant={"filled"}
                  colorScheme="blue"
                  alignItems={"center"}
                  type="file"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                isDisabled={isUploading}
                onClick={onClose}
                colorScheme="blue"
                variant={"outline"}
              >
                Cancel
              </Button>
              <Button
                isLoading={isUploading}
                onClick={uploadAnswerScript}
                colorScheme="blue"
                isDisabled={!file}
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className="flex justify-between relative">
        {note.marks == "NOT VALUED" ? (
          <Badge
            position={"absolute"}
            rounded={"full"}
            colorScheme="orange"
            variant={"solid"}
            fontSize={"lg"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            w={"6"}
            h={"6"}
            top={"0.5"}
            right={"0.5"}
            pb={"1"}
          >
            •
          </Badge>
        ) : note.marks !== "-" ? (
          <Badge
            position={"absolute"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            rounded={"full"}
            colorScheme="green"
            variant={"solid"}
            fontSize={"lg"}
            w={"10"}
            h={"10"}
            top={"0.5"}
            right={"0.5"}
          >
            {note.marks}
          </Badge>
        ) : null}
        <b className="flex-1 whitespace-nowrap">Subject Code</b>{" "}
        <span className="text-left flex-1">{note.subcode}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Unit No.</b>{" "}
        <span className="text-left flex-1">{note.unitno}</span>
      </div>
      {note.submission_date && (
        <div className="flex justify-between my-3">
          <b className="flex-1">Conduction Date</b>{" "}
          <span className="text-left flex-1">{note.submission_date}</span>
        </div>
      )}
      <hr className="" />
      <div className="py-3 flex flex-col justify-between px-2 items-center">
        <HStack w={"full"} justifyContent={"space-between"}>
          <span className="text-inherit text-sm">{note.date}</span>
          <Button
            variant={"unstyled"}
            onClick={async () => {
              const file = await Supabase()
                .storage.from("media")
                .download(note.file_url);
              const path = window.URL.createObjectURL(file.data);
              const link = document.createElement("a");
              link.href = path;
              link.setAttribute("download", `Unit Test-${note.subcode}-Unit ${note.unitno}-test ${note.testno}.pdf`);
              document.body.appendChild(link);

              // Start download
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);
            }}
          >
            <AiOutlineDownload className="text-[24px] text-inherit hover:scale-110 active:scale-95 cursor-pointer" />
          </Button>
        </HStack>
        <HStack w={"full"}>
          {note.status == "SUBMITTED" ? (
            <Tag
              colorScheme="whatsapp"
              justifyContent={"center"}
              size={"lg"}
              variant={"solid"}
              py={"3"}
              w={"full"}
            >
              Submitted
              <TagRightIcon>
                <AiOutlineCheckCircle className="text-2xl" />
              </TagRightIcon>
            </Tag>
          ) : (
            <Button
              w={"full"}
              onClick={onOpen}
              colorScheme="blue"
              leftIcon={<AiOutlineFilePdf className="text-xl" />}
            >
              Submit Answer Script
            </Button>
          )}
        </HStack>
      </div>
    </motion.div>
  );
}

export default JBPCard;
