import React from "react";
import { AiOutlineUser } from "react-icons/ai";

function TeacherDCard({ lecture }) {
  return (
    <div className="group rounded-md px-5 py-4 border first:ml-0 ml-3 flex flex-col items-center cursor-pointer md:hover:bg-primary md:hover:text-white active:scale-95">
      <div>
        <AiOutlineUser className="text-3xl text-textSecondary md:group-hover:text-white w-[70px]" />
      </div>
      <h1 className="font-bold text-md first-letter mt-2 whitespace-nowrap">
        {lecture.name}
      </h1>
      <h3 className="mt-3 text-xs whitespace-nowrap">{lecture.designation}</h3>
    </div>
  );
}

export default TeacherDCard;
