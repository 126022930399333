import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Supabase from "../utils/Supabase";
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

function CircularModal({ children, url,title,other_info }) {
  const supabase = Supabase();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const layoutPlugin = defaultLayoutPlugin();

  return (
    <>
      <Modal size={"5xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody h={"90vh"}>
            <HStack>
              {other_info && <p className="text-xl pb-5">{other_info}</p>}
            </HStack>
            <VStack h={"full"} w={"full"}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  plugins={[layoutPlugin]}
                  fileUrl={
                    supabase.storage.from("media").getPublicUrl(url).data
                      .publicUrl
                  }
                />
              </Worker>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {children({ onOpen })}
    </>
  );
}

export default CircularModal;
