import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeachers } from "../../store/teachers.slice";
import Slider from "../utils/Slider";
import TeacherDCard from "./TeacherDCard";

function Teachers() {
  const lecturesData = useSelector((state) => state.teachers.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeachers());
  }, []);
  return (
    <div className="py-primaryMob px-primaryMob md:py-0 md:px-5">
      <div className="w-full h-full bg-white rounded-[10px] shadow-xl px-[25px] py-primaryMob border">
        <header className="flex justify-center items-center">
          <h1 className="font-bold text-xl">Teachers</h1>
        </header>
        <Slider>
          {lecturesData.map((lecture, index) => {
            return <TeacherDCard key={index} {...{ lecture }} />;
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Teachers;
