import React from "react";
import { useState } from "react";
import JBPSelect from "../utils/JBPSelect";
import JBButton from "../utils/JBButton";
import { useDispatch } from "react-redux";
import {
  getAttendenceDay,
  getAttendenceMonth,
} from "../../store/attendence.slice";
import { useEffect } from "react";

const DatePicker = () => {
  const [cat, setCat] = useState("day");
  const [month, setMonth] = useState("1");
  const [year, setYear] = useState("2021");
  const [date, setDate] = useState(null);
  const dispatch = useDispatch();

  function onCheck() {
    if (cat === "day") {
      dispatch(getAttendenceDay({ acadyear: year, date }));
    } else {
      dispatch(getAttendenceMonth({ acadyear: year, month }));
    }
  }
  return (
    <div className="w-full mx-5 flex flex-wrap justify-center gap-5 items-end first:m-none">
      <JBPSelect
        title={""}
        placeholder={"Select Year"}
        options={["2021", "2022", "2023", "2024"]}
        onChange={(e) => setYear(e.target.value)}
        value={year}
      />
      <JBPSelect
        title={""}
        placeholder={"Select Category"}
        options={["day", "month"]}
        onChange={(e) => setCat(e.target.value)}
        value={cat}
      />
      {cat == "day" ? (
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="px-2 py-2 self-end outline-none cursor-pointer border border-slate-600 shadow-lg text-slate-500 rounded-md"
        />
      ) : (
        <div className="mt-3">
          <JBPSelect
            placeholder={"Select Month"}
            onChange={(e) => setMonth(e.target.value)}
            value={month}
            options={Array(12)
              .fill(0)
              .map((_v, i) => i + 1)}
          />
        </div>
      )}
      <JBButton onClick={onCheck}>Check</JBButton>
    </div>
  );
};

export default DatePicker;
