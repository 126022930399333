import React from "react";
import { useSelector } from "react-redux";
import { NavLink as Link, useLocation } from "react-router-dom";

function Profile() {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  return (
    <Link
      to={"/profile"}
      end
      className={({ isActive }) =>
        "group md:active:scale-95 md:rounded-[10px] m-0 p-0 md:border flex md:px-[18px] md:py-[5px] justify-between items-center self-end md:scale-90 md:hover:bg-primary md:hover:scale-100 ease-in-out duration-300 hover:cursor-pointer" +
        (isActive && " md:bg-primary md:text-white md:hover:scale-90")
      }
    >
      <div>
        <h1 className="hidden md:block font-bold text-sm md:group-hover:text-gray-100 mr-1">
          {user?.name}
        </h1>
        <span className=" md:group-hover:text-gray-100 hidden md:block">
          {user?.reg_no}
        </span>
      </div>
      <img
        src="/user.png"
        alt="avatar"
        className={
          "h-[35px] w-[35px] md:h-[45px] md:ml-[10px] md:w-[45px] object-contain border-2 rounded-md" +
          (location.pathname === "/profile" && " border-4 border-primary")
        }
      />
    </Link>
  );
}

export default Profile;
