import React from "react";
import JBPSelect from "../utils/JBPSelect";
import DataTable from "../utils/DataTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../store/schedule.slice";
import { useState } from "react";
import Loader from "../utils/Loader";
import {
  Card,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Spinner,
} from "@chakra-ui/react";

const Schedule = () => {
  const dispatch = useDispatch();
  const [test, setTest] = useState("");
  const schedule = useSelector((state) => state.schedule.data);
  const isPending = useSelector((state) => state.schedule.pending);

  useEffect(() => {
    dispatch(getSchedule({ test }));
  }, [test]);

  return (
    <div className="px-primaryMob pb-cardH h-full">
      <div className="lg:flex flex-col md:flex-row w-full">
        <FormControl>
          <FormLabel>Test Type</FormLabel>
          <Select w={"52"} bg={"white"} shadow={"md"} h={"8"} value={test} onChange={(e) => setTest(e.target.value)}>
            <option value="">Select Test Type</option>
            <option value="T1">T1</option>
          </Select>
        </FormControl>
      </div>
      {!test && (
        <Center pb={"48"} h={"full"}>
          <Card p={"10"} border={"1px"} borderColor={"blue"}>
            <Heading size={"md"}>Select Test Type</Heading>
          </Card>
        </Center>
      )}
      {test && (
        <div className="py-10">
          {isPending ? (
            <Center h={"full"}>
              <Spinner color="blue" size={"xl"} />
            </Center>
          ) : (
            <DataTable data={schedule} />
          )}
        </div>
      )}
    </div>
  );
};

export default Schedule;
