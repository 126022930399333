import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMarks } from "../../store/marks.slice";
import DataTable from "../utils/DataTable";
import JBPSelect from "../utils/JBPSelect";
import Loader from "../utils/Loader";

function Marks() {
  const [test, setTest] = useState("WT1");
  const [acadyear, setAcadyear] = useState("2021");
  const [sem, setSem] = useState("1");
  const dispatch = useDispatch();
  const marks = useSelector((state) => state.marks.data);
  const isPending = useSelector((state) => state.marks.pending);
  const isError = useSelector((state) => state.marks.error);
  const total = useSelector((state) => state.marks.total);

  useEffect(() => {
    dispatch(getMarks({ test, acadyear, sem }));
  }, [test, acadyear, sem]);
  return (
    <div className="px-primaryMob pb-cardH">
      <div className="lg:flex flex-col md:flex-row w-full">
        <JBPSelect
          title={"Academic Year"}
          placeholder={"Select Year"}
          options={["2021", "2022", "2023", "2024"]}
          onChange={(e) => setAcadyear(e.target.value)}
          value={acadyear}
        />
        <JBPSelect
          title={"Semester"}
          placeholder={"Select Semester"}
          options={new Array(6).fill(0).map((v, i) => i + 1)}
          onChange={(e) => setSem(e.target.value)}
        />
        <JBPSelect
          title={"Exam/Test Type"}
          placeholder={"Select Type"}
          options={[
            "Writing Test 1",
            "Writing Test 2",
            "Writing Test 3",
            "Multiple Choice Questions",
            "Open Book Test",
            "Skill Test 1",
            "Skill Test 2",
            "Skill Test 3",
            "Internal Assessment 1",
            "Internal Assessment 2",
            "Internal Assessment 3",
            "Semester End Exam",
          ]}
          values={[
            "WT1",
            "WT2",
            "WT3",
            "MCQ",
            "OBT",
            "ST1",
            "ST2",
            "ST3",
            "IA1",
            "IA2",
            "IA3",
            "SEE",
          ]}
          onChange={(e) => setTest(e.target.value)}
          value={test}
        />
      </div>
      {isPending && (
        <div className="p-primaryMob">
          <Loader />
        </div>
      )}
      {isError && (
        <div className="py-20 px-primaryMob w-full text-center md:text-left text-xl font-bold">
          {isError.msg} !
        </div>
      )}
      <div className="py-10">
        {total && (
          <div className="flex gap-3 py-5">
            <div className="bg-white shadow-md border px-4 py-2 flex flex-col justify-center items-center">
              <h1 className="font-bold text-sm md:text-md">Total FIA Marks</h1>
              <span className="text-xl py-2 text-primary font-bold">
                {total.fia}
              </span>
            </div>
            <div className=" bg-white shadow-md border px-4 py-2 flex flex-col justify-center items-center">
              <h1 className="font-bold text-sm md:text-md">Total SEE Marks</h1>
              <span className="text-xl py-2 text-primary font-bold">
                {total.see}
              </span>
            </div>
            <div className="bg-white shadow-md border px-4 py-2 flex flex-col justify-center items-center">
              <h1 className="font-bold text-sm md:text-md">Overall Total</h1>
              <span className="text-xl py-2 text-primary font-bold">
                {total.overall}
              </span>
            </div>
          </div>
        )}
        <DataTable data={marks} />
      </div>
    </div>
  );
}

export default Marks;
