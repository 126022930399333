import React from "react";
import Assignments from "./Assignments";
import Attendence from "./Attendence";
import Circulars from "./Circulars";
// import Syllabus from "./Syllabus";
import Teachers from "./Teachers";
import TimeTable from "./TimeTable";

function Home() {
  return (
    <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3 pb-10">
      <Circulars />
      <div>
        <Assignments />
        {/* <Syllabus /> */}
        <Attendence />
      </div>
      <TimeTable />
      <div className="lg:col-span-2 lg:grid lg:grid-cols-2 xl:block xl:col-span-1 pb-16">
        <Teachers />
      </div>
    </div>
  );
}

export default Home;
