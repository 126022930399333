import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getMarks = createAsyncThunk(
  "/marks/get",
  async (
    { sem, acadyear, test },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const formData = new FormData();
      const { branch, reg_no } = getState().auth.user;
      formData.append("sem", sem);
      formData.append("branch", branch);
      formData.append("acadyear", acadyear);
      formData.append("test", test);
      formData.append("regno", reg_no);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "marks.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue({ data: response.data, type: test });
    } catch (error) {
      return rejectWithValue({ msg: error.response.data?.msg });
    }
  }
);

export const MarksSlice = createSlice({
  name: "marks",
  reducers: {},
  initialState: {
    data: [],
    pending: false,
    error: null,
  },
  extraReducers: {
    [getMarks.fulfilled]: (state, action) => {
      state.pending = false;
      state.error = null;
      console.log(action.payload);
      if (action.payload?.type === "SEE") {
        state.data = action.payload.data.marks?.map(
          ({ subcode, subname, fia, see, total }) => {
            return {
              "Subject Code": subcode,
              "Subject Name": subname,
              "FIA Marks": fia,
              "SEE Marks": see,
              Total: total,
            };
          }
        );
        state.total = action.payload.data.ototal;
      } else {
        state.total = null;
        state.data = action.payload.data?.map(({ subcode, subname, marks }) => {
          return {
            "Subject Code": subcode,
            "Subject Name": subname,
            Marks: marks,
          };
        });
      }
    },
    [getMarks.pending]: (state) => {
      state.pending = true;
    },
    [getMarks.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.payload;
      state.data = [];
      state.total = null;
    },
  },
});
