import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssignments, sortByStatus } from "../../store/assignments.slice";
import JBPSelect from "../utils/JBPSelect";
import Loader from "../utils/Loader";
import Card from "./Card";
import { Center, Heading, Spinner } from "@chakra-ui/react";

function Assignment() {
  const assignments = useSelector((state) => state.assignments.filtered);
  const user = useSelector((state) => state.auth.user);
  const isPending = useSelector((state) => state.assignments.pending);
  const isError = useSelector((state) => state.assignments.error);

  const dispatch = useDispatch();

  useEffect(() => {
    async function intializeData() {
      await dispatch(
        getAssignments({ sem: 1, branch: "at", acadyear: "2021" })
      );
    }
    intializeData();
  }, [user]);

  function sort(filterValue) {
    dispatch(sortByStatus(filterValue));
  }

  if(isPending){
    return (
      <Center h={"full"} pb={"48"}>
        <Spinner color={"blue"} size={"xl"}/>
      </Center>
    )
  }

  if(isError){
    return(
      <Center h={"full"} pb={"48"}>
        <Heading size={"lg"}>{isError}</Heading>
      </Center>
    )
  }

  return (
    <div className="px-primaryMob">
      <div>
        <JBPSelect
          options={["All", "Not Submitted", "Pending", "Approved", "Denied"]}
          onChange={(e) => sort(e.target.value)}
        />
      </div>
      {isPending && (
        <div className="flex w-full justify-center items-center py-16">
          <Loader />
        </div>
      )}
      <div
        className={`pb-16 md:py-10  md:grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4`}
      >
        {Object.values(assignments).map((assignment, index) => {
          return <Card {...{ assignment }} key={index} />;
        })}
      </div>
    </div>
  );
}

export default Assignment;
