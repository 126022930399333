import React from "react";
import Header from "./Header";
import { Routes, Route, Outlet } from "react-router-dom";
import { Links } from "../Navbar";
import NotFound from "../utils/NotFound";
import CircularModal from "../Circular/CircularModal";
import ProfileDetail from "./ProfileDetail";

function Main() {
  return (
    <div className="pb-[55px] md:pb-[0px] mt-[55px] h-[100vh] md:overflow-y-scroll customScroll md:h-[100vh] md:w-full md:mt-0 overflow-x-hidden">
      <Header />
      <Outlet />
      <Routes>
        {Links.map((link, index) => {
          return (
            <Route key={index} path={link.path} element={<link.Component />} />
          );
        })}
        <Route path="*" element={<NotFound />} />
        <Route path="circular/:filename" element={<CircularModal />} />
        <Route path="/profile" element={<ProfileDetail />} />
      </Routes>
    </div>
  );
}

export default Main;
