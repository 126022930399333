import React from "react";

function JBInput({ title, placeholder, onChange, value, className, disabled }) {
  return (
    <div className={"flex flex-col  mt-3 lg:ml-5 md:ml-0 " + className}>
      <label className="flex flex-col  text-md py-2 font-bold">{title}</label>
      <input
        {...{ onChange }}
        {...{ value }}
        {...{ placeholder }}
        {...{ disabled }}
        className="w-fit bg-bg shadow-md active:shadow-none disabled:active:scale-100 disabled:shadow-none active:scale-95 text-md py-2 px-2 rounded-[8px] text-textSecondary outline-primary border border-textSecondary"
      />
    </div>
  );
}

export default JBInput;
