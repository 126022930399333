import React from "react";

import Card from "./Card";
import Attend from "./Attend";
import Hero from "./Hero";
import DatePicker from "./DatePicker";

const Attendence = () => {
  return (
    <div>
      <div className="">
        <div className="w-full p-primaryMob flex flex-col md:flex-row justify-around items-center md:items-center flex-wrap">
          <Card />
          <Attend />
        </div>
        <div>
          <DatePicker />
          <Hero />
        </div>
      </div>
    </div>
  );
};

export default Attendence;
