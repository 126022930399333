import React from "react";
import { AiOutlineArrowLeft, AiOutlineDisconnect } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function NotFound() {
  const user = useSelector((state) => state.auth.user);
  return (
    <div className="flex  flex-col bg-bg justify-center items-center h-full w-full">
      <h1 className="text-8xl text-primary font-bold animate-bounce flex">
        404
        <AiOutlineDisconnect className="ml-5" />
      </h1>
      <h3 className="text-2xl mt-4 text-black font-bold">
        Oops! Page Not Found
      </h3>
      <Link
        to={user == null ? "/login" : "/"}
        className="mt-4 flex items-center text-lg font-bold text-primary hover:underline"
      >
        <AiOutlineArrowLeft />
        <span className="ml-2">
          {user == null ? "go Back" : "Back to Home"}
        </span>
      </Link>
    </div>
  );
}

export default NotFound;
