import React, { useLayoutEffect, useRef, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

function Slider({ children }) {
  const [sliderW, setSliderW] = useState(null);
  const [slideOffset, setSlideOffset] = useState(null);
  const [sliderX, setSliderX] = useState(0);
  const sliderRef = useRef();
  const sliderobserver = new ResizeObserver(handleResize);
  const sliderOffsetobserver = new ResizeObserver(handleOffResize);
  const slideOffsetRef = useRef();

  useLayoutEffect(() => {
    sliderobserver.observe(sliderRef.current);
    sliderOffsetobserver.observe(slideOffsetRef.current);
    return () => {
      sliderOffsetobserver.unobserve(slideOffsetRef.current);
      sliderobserver.unobserve(sliderRef.current);
    };
  }, []);

  function handleResize() {
    setSliderW(sliderRef.current.offsetWidth);
  }
  function handleOffResize() {
    setSlideOffset(slideOffsetRef.current.offsetWidth);
  }
  function next() {
    const nextX = sliderX - slideOffset;
    const endX = -(sliderW - slideOffset);
    console.log("endX", endX, "nextX", nextX, sliderW - slideOffset);
    if (nextX <= endX) setSliderX(endX);
    else setSliderX(nextX);
  }

  function prev() {
    const nextX = sliderX + slideOffset;
    if (nextX >= 0) setSliderX(0);
    else setSliderX(nextX);
  }

  return (
    <div
      className="relative overflow-scroll customScrollHide lg:overflow-hidden mx-w-full"
      ref={slideOffsetRef}
    >
      {/* left arrow */}
      <div className="absolute left-0 top-0  h-full items-center  px-1 z-10 hidden lg:flex">
        <div
          onClick={prev}
          className=" w-[40px] h-[40px] shadow-lg bg-white rounded-full flex items-center justify-center cursor-pointer active:scale-90 border"
        >
          <AiOutlineArrowLeft className="text-[20px] text-primary" />
        </div>
      </div>

      <div
        style={{
          transform: `translateX(${sliderX}px)`,
        }}
        className="flex py-primaryMob md:px-[48px] px-3  w-fit ease-linear duration-300"
        ref={sliderRef}
      >
        {children}
      </div>

      {/* right Arrow */}
      <div className="absolute right-0 top-0  h-full items-center px-1 hidden lg:flex">
        <div
          onClick={next}
          className=" w-[40px] h-[40px] shadow-lg bg-white rounded-full flex items-center justify-center cursor-pointer active:scale-90 border"
        >
          <AiOutlineArrowRight className="text-[20px] text-primary" />
        </div>
      </div>
    </div>
  );
}

export default Slider;
