import React, { useEffect } from "react";
import Card from "./Card";
import { getCirculars } from "../../store/circulars.slice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../utils/Loader";
import { Center, Heading, Spinner } from "@chakra-ui/react";

const Circular = () => {
  const circulars = useSelector((state) => state.circulars.data);
  const isPending = useSelector((state) => state.circulars.pending);
  const isError = useSelector((state) => state.circulars.error);

  const dispatch = useDispatch();
  useEffect(() => {
    async function intializeData() {
      await dispatch(getCirculars({ acadyear: "2021" }));
    }
    intializeData();
  }, []);

  if(isPending){
    return (
      <Center h={"full"} pb={"48"}>
        <Spinner color={"blue"} size={"xl"}/>
      </Center>
    )
  }

  if(isError){
    return(
      <Center h={"full"} pb={"48"}>
        <Heading size={"lg"}>{isError}</Heading>
      </Center>
    )
  }

  return (
    <div className="px-primaryMob">
      <div
        className={`pb-16 md:py-10  md:grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4`}
      >
        {circulars?.map((circular, index) => {
          return <Card {...{ circular }} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Circular;
