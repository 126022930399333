import React from "react";
import { useSelector } from "react-redux";
import JBInput from "../utils/JBInput";

function ProfileDetail() {
  const user = useSelector((state) => state.auth.user);
  return (
    <div className="px-primaryMob py-primaryMob">
      <h1 className="font-bold text-2xl text-primary indent-3">Your Profile</h1>
      <div className="grid w-full sm:grid-cols-2  md:grid-cols-2 lg:w-1/2">
        <JBInput title={"Register No."} value={user?.reg_no} disabled />
        <JBInput title={"Name"} value={user?.name} disabled />
        <JBInput title={"Year"} value={user?.year} disabled />
        <JBInput title={"Branch"} value={user?.branch} disabled />
        <JBInput title={"Father Name"} value={user?.fname} disabled />
        <JBInput title={"Mother Name"} value={user?.mname} disabled />
        <JBInput title={"D.O.B"} value={user?.dob} disabled />
        <JBInput title={"Gender"} value={user?.gender} disabled />
        <JBInput title={"Phone No."} value={user?.phno} disabled />
        <JBInput title={"Year Of Join"} value={user?.yoj} disabled />
        <JBInput title={"Last Login"} value={user?.lastlogin} disabled />
      </div>
    </div>
  );
}

export default ProfileDetail;
