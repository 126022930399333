import Main from "./components/Main";
import Navbar from "./components/Navbar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "./components/utils/NotFound";
import Login from "./components/Login";
import { useEffect } from "react";

function App() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user == null) navigate("/login");
    else navigate("/");
  }, [user]);

  return (
    <div className="App md:flex md:h-[100vh]">
      <ToastContainer />
      <Routes>
        {user == null ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </>
        ) : (
          <>
            <Route
              path="/*"
              element={
                <>
                  <Navbar />
                  <Main />
                </>
              }
            />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
