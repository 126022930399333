import React from "react";
import { motion } from "framer-motion";

function DataTable({ data }) {
  if (typeof data == "undefined") return null;
  return (
    <motion.table
      layout
      animate={{ opacity: 1, y: 0, scale: 1 }}
      initial={{ opacity: 0, y: 20, scale: 0.7 }}
      transition={{ duration: 0.4, type: "spring" }}
      className="md:border md:px-1 md:py-3 md:shadow-lg"
    >
      {/* Table Header */}
      <thead>
        <tr className="bg-white hidden md:table-row">
          {Object.keys(data[0] || {}).map((colName, index) => {
            return (
              <th
                key={index}
                className="dark:text-white dark:bg-dark px-2 text-center py-2 border-b text-md"
              >
                {colName}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {data?.map((object, index) => {
          return (
            <tr
              key={index}
              className={`dark:bg-lightDark dark:even:bg-dark dark:text-white bg-white block md:table-row mt-[15px] md:bg-transparent md:even:bg-white py-2 rounded-md border shadow-lg md:shadow-none md:border-none`}
            >
              {Object.keys(object).map((key, index) => {
                return (
                  <td
                    key={index}
                    className="px-9 py-2 md:border-b flex md:table-cell justify-between text-sm md:text-md"
                  >
                    <h1 className="flex-1 font-bold md:hidden">{key}</h1>
                    <span className="text-left flex-1">{object[key]}</span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </motion.table>
  );
}

export default DataTable;
