import React from "react";
import gradient from "random-gradient";
import { v4 as uuid4 } from "uuid";
import { Link } from "react-router-dom";

function Circular({ circular }) {
  const bgGradient = { background: gradient(uuid4(), "vertical") };

  return (
    <Link
      to={"/circular/" + circular.filename}
      style={bgGradient}
      className="flex justify-center items-center  text-white  shadow-lg border rounded-[10px] ml-[34px] last:mr-[0px] first:ml-[0px] max-w-[250px] max-h-[132px] h-[132px] hover:cursor-pointer md:hover:scale-105 ease-in-out duration-300"
    >
      <div className="flex h-[110%] w-[110%] border-2 border-white rounded-[10px] bg-[rgba(0,0,0,0.2)] py-[16px] px-[17px] backdrop-blur-lg">
        <div>
          <span className="text-xs">{circular.date}</span>
          <h2 className="font-bold text-md mt-1">{circular.category}</h2>
          <p className="max-w-full w-[180px] truncate flex-nowrap text-ellipsis text-sm mt-2">
            {circular.other_info}
          </p>
        </div>
        <div>
          <div className="h-[50px] w-[50px] bg-bg rounded-full flex justify-center items-center">
            <span className="text-black font-bold">{circular.branch}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Circular;
