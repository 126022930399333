import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCirculars } from "../../store/circulars.slice";
import Slider from "../utils/Slider";
import Circular from "./Circular";

function Circulars() {
  const CircularsData = useSelector((state) => state.circulars.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCirculars({ acadyear: "2021" }));
  }, []);

  return (
    <div className="w-full lg:col-span-2 xl:col-span-3">
      <h3 className="pl-primaryMob font-bold pt-primaryMob text-xl">
        Circulars
      </h3>
      <Slider>
        {CircularsData?.map((circular, index) => {
          return <Circular key={index} {...{ circular }} />;
        })}
      </Slider>
    </div>
  );
}

export default Circulars;
